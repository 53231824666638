@use "../../assets/scss/utilities" as *;
.big-wrapper-apply-for-leave {
  // display: flex;
  margin-left: 27px;
  margin-right: 13px;
  margin-bottom: 28px;

  @media (max-width: 1193px) {
    display: block;
  }
}
.style-for-employee {
  display: block;
}
.margin-botom {
  .css-5epm38-MuiDivider-root {
    margin: 0px;
  }
  .css-1fmptt2 {
    margin: 0px;
  }
}
.left-child-wrraper {
  @media (max-width: 1193px) {
    width: auto;
    border-right: none;
  }
}
.leave-request-progressbar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 698px) {
    gap: 18%;
  }

  @media (max-width: 375px) {
    gap: 20%;
  }
}
.RoundProgBar {
  margin-right: 92px;
}

.leave-request-form-wrraper {
  .form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    input,
    .select {
      font-weight: 600;
      height: 64px;
      border: 2px solid $lighter-gray-14;
      box-sizing: border-box;
      border-radius: 14px;
      font-size: 15px;
      padding: 0px;
      padding-left: 0px;
      padding-right: 18px;
    }

    .css-1d8n9bt {
      height: 64px;
    }

    select:disabled {
      font-weight: 600;
      color: black;
    }

    textarea {
      padding: 19px;
      // width: 741px;
      width: 100%;
      font-size: 17px;

      height: 100%;
      overflow: auto;
      border: 2px solid $lighter-gray-14;
      box-sizing: border-box;
      border-radius: 15px;
      font-weight: 600;
      color: hsl(0, 0%, 20%);

      @media (max-width: 1193px) {
        width: 100%;
      }
    }
    label {
      margin-bottom: 10px;
      font-weight: 600;
      color: $dark-gray-1;
    }
  }

  .second-wrraper-form {
    label {
      margin-bottom: 10px;
      font-weight: 600;
      color: $dark-gray-1;
    }
    display: flex;
    gap: 83px;
    margin-bottom: 32px;
    .form-control {
      input,
      .select {
        padding-left: 0px;
        //width: 328px;
        height: 64px;
        font-weight: 600;
        border: 2px solid $lighter-gray-14;
        box-sizing: border-box;
        border-radius: 14px;
        margin-bottom: 26px;
      }
    }

    .field-global-wrapper {
      width: 328px;
      height: 64px;
      border: 2px solid $lighter-gray-14;
      font-weight: 600;
      &:hover {
        border: 2px solid $sky-blue;
      }
    }
    .form-control-input-with-icons-leave-request {
      width: 100%;
      & .field-global-wrapper {
        width: 100%;
        margin-top: 10px;
        @media (max-width: 1193px) {
          width: 100%;
        }
      }
    }
  }

  .field-global-wrapper .field-intern-wrapper input {
    background: $white;
    font-weight: 600;
    font-size: 15px;
    color: $darken-gray-1;
    margin-left: -11px;
  }
}

.btn-leave-request {
  width: 100%;
  height: 64px;
  border-radius: 14px;
  @media (max-width: 1193px) {
    width: 100%;
  }
}

.BlueButton {
  background-color: $sky-blue;
  color: $white;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.selectLeaveRequest {
  & .css-1vp9nu5-control {
    border-radius: 14px !important;
  }
  .css-1t8y0t9-control {
    padding-left: 0px;
    // width: 90%;
    height: 64px;
    // font-weight: 600;
    border: 2px solid $lighter-gray-14;
    box-sizing: border-box;
    border-radius: 14px;
    background: none;
    @media (max-width: 1193px) {
      width: 100%;
    }
    &:focus,
    &:hover {
      border: unset !important;
      border: 2px solid $sky-blue !important;
    }
  }
  .css-qc6sy-singleValue {
    margin-left: 10px;
  }
  .css-1w0hcsb-control {
    border-color: $sky-blue;
  }
  .css-319lph-ValueContainer {
    height: 64px;
    font-weight: 600;
    box-sizing: border-box;
  }
  .css-1wy0on6 {
    height: 64px;
  }
  label {
    margin-bottom: 10px;
    font-weight: 600;
    color: $dark-gray-1;
  }
  .css-14el2xx-placeholder {
    padding-left: 8px;
  }
}

.disabledselect {
  pointer-events: none;
  label {
    margin-bottom: 10px;
    font-weight: 600;
    color: $dark-gray-1;
  }
}
.disabledtitle {
  color: $sky-blue;
}
.css-b62m3t-container {
  border-color: $sky-blue;
}
.noticeUpdated {
  width: 100%;
}
