@use "../../assets/scss/utilities" as *;

.Notif_container__ {
  min-width: 374px;
  .Notif_container__header {
    img {
      width: 92%;
      height: 92%;
      border-radius: 50%;
      @media (max-width: 375px) {
        display: none;
      }
    }
  }
}
.dropdownMenuiLogout {
  .jss2 .MuiPaper-root {
    border-radius: "16px !important";
  }
  .jss2 {
    border-radius: "16px !important";
  }
  .MuiPaper-root {
    border-radius: "16px !important";
  }
}

.icon-button_logout {
  margin-right: 40px;
  display: flex;
  align-items: center;
  align-items: center;
  cursor: pointer;
}

//notif icon

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: $darken-gray-1;
  background: $lighter-gray-10;
  border: none;
  outline: none;
  border-radius: 50%;
}
.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background: $lighter-gray-10;
}

.icon-button__badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: $red;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}

.settings-wrapper {
  svg {
    width: 34px;
    height: 34px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  span {
    font-size: 18px;
    font-weight: 600;
  }
}
.changed-color {
  span {
    color: $lighter-red;
  }

  svg {
    color: $lighter-red;
  }
}
