@use "../../assets/scss/utilities" as *;

.color-picker-component {
  width: 37px;
  margin-top: 14px;
  border: 2px solid $lighter-gray-14;
  border-radius: 6px;
  height: 40px;

  & input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 37px;
    height: 40px;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &::-webkit-color-swatch {
      border-radius: 4px;
      border: none;
    }
    &::-moz-color-swatch {
      border-radius: 4px;
      border: none;
    }
  }
}
