/* ------ colors ----------
-----------------------------*/
//gray

$light-gray: #fcfcfc;

$lighter-gray-1: #c4c4c4;
$lighter-gray-2: #c1c1c1;
$lighter-gray-3: #c4c4c4;
$lighter-gray-4: #ccc;
$lighter-gray-5: #d9d9d9;
$lighter-gray-6: #cdd2d7;
$lighter-gray-7: #cfe6f1;
$lighter-gray-8: #f3f4f6;
$lighter-gray-9: #edecec;
$lighter-gray-10: #e8e8e8;
$lighter-gray-11: #f9fdff;
$lighter-gray-12: #fafbfc;
$lighter-gray-13: #eaeaea;
$lighter-gray-14: #ebebeb;

$dark-gray: #b7b9be;
$dark-gray-1: #9b9ead;
$dark-gray-2: #a8a8a8;
$dark-gray-3: #a3a3a3;

$darker-gray: #a2a2a7;
$darker-gray-1: #8e8e8e;
$darker-gray-2: #999;
$darker-gray-3: #808295;
$darker-gray-4: #606060;
$darker-gray-5: #636363;

$darken-gray: #555;
$darken-gray-1: #333333;
$darken-gray-2: #1a2027;

//red

$red: #df2020;

$light-red: #f02849;
$light-red-1: #fa3839;
$light-red-2: #ee3535;

$lighter-red: #f45158;
$lighter-red-1: #f15e5e;
$lighter-red-2: #ed6b6c;
$lighter-red-3: #fbd2d3;
$lighter-red-4: #fddddf;
$lighter-red-5: #f1f1f1;
$lighter-red-6: #f7f7f7;

//purple

$purple: #784df5;

//orange

$orange: #f18541;

$light-orange: #fea34e;

//yellow

$yellow: #f1c041;

$light-yellow: #ffdb5e;

$lighter-yellow: #f1cb41;

$dark-yellow: #eebf16;

//black

$black: #000000;

//white

$white: white;

//blue

$blue: #0560fc;

$sky-blue: #2ba7df;

$light-blue: #0aafdf;

$lighter-blue: #8ad5f7;
$lighter-blue-1: #f0f6f8;
$lighter-blue-2: #dcf5fe;
$lighter-blue-3: #eaf6ff;

$darker-blue: #4588fd;
$darker-blue-1: #3870a3;

//green

$light-green: #3cb414;

$lighter-green: #3ae102;

$lighten-green: #c7e6bd;
$lighten-green-1: #e6fbdf;

/* ------ width ----------
-----------------------------*/

$width: 94px;
$width1: 75px;
$width2: 110px;

/* ------ border ----------
-----------------------------*/

$red-border: 1px solid $red;
$sky-blue-border: 1px solid $darker-blue;
$trendingUpborder: 1px solid $dark-gray-1;
