@use "../../assets/scss/utilities" as *;

.negotiation-form-wrapper {
  max-width: 750px;

  // padding: 40px 40px 0 40px;
  .size {
    font-size: 16px;
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 10px;
    font-weight: 500, Medium;
    color: $darker-gray-5;
    font-size: 13;
  }

  .field-global-wrapper {
    width: 100%;
    height: 46px;
    border-radius: 6px;
    margin-top: 8px;

    .DatePicker .DatePicker__input {
      margin-left: -12px;
      font-weight: 600;
    }
  }

  .form-control {
    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid $lighter-gray-14;
      box-sizing: border-box;
      border-radius: 6px;
      margin-top: 8px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;

    span {
      font-weight: 500, Medium;
      font-size: 14px;
      color: $darker-gray-5;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      margin-left: 8px;
    }
  }

  .label-wrapper-description {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      color: $darker-gray-5;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      margin-left: 8px;
      margin-left: 8px;
    }
  }
  & > form {
    & > .negotiation-date {
      margin-bottom: 20px;
    }
  }
}
.negotiation-form-wrapper-details {
  margin-bottom: 4%;
  padding: 0px 14px;
}
.diableddescription {
  pointer-events: none;
}
