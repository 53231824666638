@use "../../assets/scss/utilities" as *;

.btn__add_details {
  width: 209px;
  height: 49px;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  background: $light-blue;
  border-radius: 8px;
  border: none;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Poppins";
  color: $white;

  @media (max-width: 768px) {
    display: none;
  }
}
.add-btn-small-version {
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
    width: 55px;
    height: 45px;
    display: flex;
    font-weight: 600;
    font-size: 15px;
    background: $light-blue;
    border-radius: 8px;
    border: none;
    align-items: center;
    justify-content: center;
    color: $white;
    margin-left: 5px;
  }
}
.LeaveBalance__bigcontainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  gap: 27px;
  margin-bottom: 41px;
  @media (max-width: 486px) {
    justify-content: center;
  }
}

.LeaveHistory_border {
  border-bottom: 1px solid $lighter-gray-14;
}

.employee_details__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  margin-bottom: 50px;
  @media (max-width: 1116px) {
    display: block;
  }
}
.header_employee {
  display: flex;
  justify-content: space-between;

  .employee_filters {
    display: flex;
    gap: 20px;
    padding-bottom: 27px;

    @media (max-width: 462px) {
      flex-direction: column;
      gap: 7px;
    }
  }
  @media (max-width: 591px) {
    gap: 20px;
  }
}

.search-small-version {
  display: none !important;
  @media (max-width: 591px) {
    display: flex !important;
    box-sizing: border-box;
    width: 62%;
    height: 46px;
    background: $white;
    border: 1.5px solid $lighter-gray-14;
    border-radius: 8px;

    justify-content: space-between;
    align-items: center;
    padding: 0 14px;

    & > input {
      outline: none;
      border: none;
      width: 100%;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        color: $darker-gray-1;
      }
    }

    & > svg {
      color: $darker-gray-1;
    }
  }
  @media (max-width: 370px) {
    width: 77%;
  }
}
.full-size {
  width: 100% !important;
}
