@use "../../../assets/scss/utilities" as *;

.add-new-holiday-sys-big-wrapper {
  box-sizing: border-box;
  .holiday-sys-wrraper {
    margin-top: 20px;
    .css-b62m3t-container {
      position: relative;
      box-sizing: border-box;
      margin-top: 10px;
    }
    .form-control {
      display: flex;
      flex-direction: column;

      & .field-intern-wrapper {
        width: 100%;
      }
      input,
      .select {
        height: 40px;
        width: 100%;
        border-radius: 6px;

        margin-top: 10px;
        border: 1px solid $lighter-gray-14;
        box-sizing: border-box;
      }

      label {
        color: $darker-gray-5;
      }

      & > .costum-date-picker {
        & > .DatePicker {
          width: 100%;
        }
      }
    }
  }

  .holiday-details-wrraper {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 40px;
    .form-control {
      display: flex;
      flex-direction: column;

      input,
      .select {
        height: 25px;
        border-radius: 6px;
        margin-top: 15px;

        border: 1px solid $lighter-gray-14;
      }
      label {
        color: $darker-gray-5;
      }
    }
  }
}
.redButton {
  width: 130px;
  height: 44px;
  border: 1px solid $light-red-1;
  color: $red;
  background-color: $white;
  margin-right: 16px;
  font-weight: 500;
}
.edit-button {
  font-weight: 600;
  font-size: 16px;
  color: $black;
  width: 152px;
  height: 43px;
  border: 1.5px solid $lighter-gray-14;
  background: $white;
}

.backgroundButton {
  background-color: $lighter-gray-10;
}
.selectFormHoliday {
  .css-1t8y0t9-control {
    border: 1px solid $lighter-gray-14 !important;
    background: none;
  }
  label {
    color: $darker-gray-5;
  }
  .css-qc6sy-singleValue {
    font-size: 14px;
    margin-left: 7px;
  }
  margin-top: 15px;
  margin-bottom: 31%;
}
