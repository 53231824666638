@use "../../../assets/scss/utilities" as *;

.NewDepForm-wrapper {
  .form-control {
    margin-top: 10px;
    input,
    textarea {
      width: 100%;
      height: 44px;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 4px;
      margin-top: 8px;
    }
    label {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 13px;
      color: $darker-gray-5;
    }

    textarea {
      height: 113px;
    }
  }
}
.add-new-dep-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 39px;
  .redButton {
    width: 130px;
    height: 44px;
    border: 1px solid $light-red-1;
    color: $red;
    background-color: $white;
    margin-right: 16px;
    font-weight: 500;
  }
}
