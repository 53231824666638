.tab_panel_wrapper {
    .css-1q2h7u5.Mui-selected {
        color: #0aafdf;

    }

    .css-ttwr4n {
        background-color: #0aafdf;
        ;
    }
}