@use "../../../assets/scss/utilities" as *;

.user-settings-wrraper {
  height: 100%;

  @media (max-width: 1548px) {
    margin-bottom: 30px;
  }

  .selectForm {
    @media (max-width: 425px) {
      display: contents;
    }
  }
  .ta-btns-group {
    @media (max-width: 425px) {
      display: contents;
    }
  }
  .form-control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: 600;
    @media (max-width: 425px) {
      display: contents;
    }

    @media (max-width: 604px) {
      display: block;
    }

    select:disabled {
      font-weight: 600;
      color: $black;
    }
    input,
    .select {
      width: 252px;
      height: 34px;
      border: 1px solid $lighter-gray-14;
      box-sizing: border-box;
      border-radius: 7px;
      font-weight: 600;
    }
    select {
      font-weight: 600;
    }

    .select {
      padding-left: 0px;
      padding-right: 0px;

      @media (max-width: 403px) {
        width: 100%;
      }
    }

    label {
      color: $dark-gray-1;
    }
  }
}
.cardOne-wrapper {
  @media (max-width: 962px) {
    margin-bottom: 40px;
  }
}

.btn-add {
  width: 284px;
  height: 50px;
  @media (max-width: 425px) {
    width: 100%;
  }
}

.item-container {
  min-height: 50%;
}
.form-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 25px;
  @media (max-width: 1165px) {
    display: inline;
  }
}

.otherstyle {
  .css-qc6sy-singleValue {
    font-weight: 600;
    color: $darken-gray;
    font-size: 14px;
    margin-left: 7px;
  }
}

.form-total-wrraper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 25px;
  margin-bottom: 43px;
  @media (max-width: 962px) {
    display: block;
  }
}

.cardOne1-wrapper {
  @media (max-width: 962px) {
    margin-bottom: 20px;
  }
}
.card1-to-wrapper {
  @media (max-width: 962px) {
    margin-bottom: 20px;
  }
}
.edit_password_wrapper {
  display: flex;
  justify-content: space-between;
  button {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-weight: bold !important;
    padding: 3px 0px;
    width: 252px;
    height: 34px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 7px;
    background: white;
    color: #555;
    padding-left: 18px;
    padding-right: 18px;
  }
  span {
    color: #0aafdf;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }
}

.update_password_modal {
  .login-btn {
    width: 100%;
  }
}

.form-control-input-with-icons {
  margin-bottom: 3%;
}
