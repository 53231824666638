@use "../../assets/scss/utilities" as *;

.sidebar_main {
  height: 100vh;
  .active-navlink {
    color: $darken-gray;
    margin-left: 17px;
    img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
      display: flex;
    }
  }
}
.normal-navlink {
  display: flex;
  align-items: center;
  width: 196px;
  height: 53px;
  padding-left: 16px;
}
.ClickedNav {
  background: $lighter-blue-2;
  border-radius: 7px;
  font-weight: 600;
  color: $light-blue;

  img {
    filter: invert(54%) sepia(17%) saturate(2017%) hue-rotate(147deg)
      brightness(99%) contrast(106%);
  }
}
.normal-navlinkMin {
  font-size: 18px;
  display: flex;
  align-items: center;
  width: 175px;
  height: 41px;
}
.withoutBackground {
  background: $white;
  left: 0%;
}
