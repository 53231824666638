@use "../../assets/scss/utilities" as *;

.disabled-input {
  pointer-events: none;
}
.form-control {
  & > .costum-date-picker {
    & > .DatePicker {
      width: 95%;
      z-index: 1 !important;
      & .DatePicker__input {
        text-align: start;
      }
    }
  }
}
