@use "../../../assets/scss/utilities" as *;

.leave-settings-view {
  position: relative;
  top: -8px;
}

.leave-setting-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 33px;
  @media (max-width: 370px) {
    gap: 10px !important;
  }
}
.leaveSettings .form-control input {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 8px;
}
.leaveSettings .form-control label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  color: $darker-gray-5;
  margin-top: 20px;
}
.span1-style {
  span {
    font-weight: 600;
    font-size: 16px;
  }
}

.leaveSettings {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  & .form-control {
    width: 97%;
  }
}
