@use "../../../assets/scss/utilities" as *;
.LeaveBalance_container {
  width: 122px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 486px) {
    width: 100%;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  svg {
    width: 30px;
    height: 30px;
   // color: #9b9ead;
  }
  .icon-style {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .LeaveBalance_containe_title {
    font-size: 15px;
    font-weight: 600;
    color: #9b9ead;
  }
  .LeaveBalance_containe__leaveDays {
    display: flex;
    align-items: center;

    .LeaveBalance_Tdays {
      color: $dark-gray-1;
      font-weight: 400;
    }
    span {
      font-size: 21px;
      font-weight: 600;
    }
  }
}
