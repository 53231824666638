@use "../../assets/scss/utilities" as *;

.select {
  .css-1t8y0t9-control {
    width: 100%;
    height: 44px;
    background: light-gray;
    border: 2px solid $darker-gray;
    border-radius: 4px;
    padding: 0px 10px 0 0px;
    margin-top: 4px;
    max-height: 200px;
    overflow-x: hidden;
    outline: none;

    &:focus,
    &:hover {
      border: unset !important;
      border: 2px solid $sky-blue !important;
    }

    .css-319lph-ValueContainer {
      padding: 0 !important;
      padding: 2px 1px !important;
    }
    .css-14el2xx-placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.75px;
      color: $darker-gray;
      padding-left: 4px;
    }
  }
}
.css-1t8y0t9-control {
  &:focus,
  &:hover {
    border: 2px solid $sky-blue !important;
  }
}

.css-1vp9nu5-control {
  box-shadow: 0 0 0 1px $sky-blue !important;
  &:focus,
  &:hover {
    border: 1px solid $sky-blue !important;
  }
}

.aligned-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
