.loading-screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(84, 84, 84, 0.4);
  .loading-screen-container {
    display: flex !important;
    justify-content: center;

    .react-logo {
      width: 200px !important;
      height: 200px !important;
    }
  }
}
