@use "../../assets/scss/utilities" as *;

.header-upload-user-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  align-items: baseline;

  @media (max-width: 768px) {
    display: block;
  }
}

.input_container {
  height: 43px;
  width: 152px;
}

.blueColor {
  color: $light-blue;
}

.add-empl-wrraper {
  .form-control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    @media (max-width: 528px) {
      display: block;
    }

    input,
    .select {
      width: 252px;
      height: 34px;
      border: 1px solid $lighter-gray-14;
      box-sizing: border-box;
      border-radius: 7px;
      @media (max-width: 375px) {
        width: 216px;
      }
    }
    .select {
      padding-left: 0px;
      padding-right: 0px;
      @media (max-width: 375px) {
        width: 216px;
      }
    }
    label {
      color: $dark-gray-1;
    }
  }
}

.table_form_wraper {
  width: 100%;
  .form-control {
    input {
      width: 137px;
      height: 34px;
    }
  }
}

.table_form_wraper_header {
  .form-control {
    display: flex;
    justify-content: flex-start;
  }

  .table_form_label_wraper {
    font-weight: 600;
    font-size: 14px;
  }
}

.form-total-wrraper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 25px;
  margin-bottom: 30px;
  @media (max-width: 962px) {
    display: inline;
  }
}

.errorWraper-add-user {
  max-width: 252px;
}

.displayImg {
  display: none;
}

.select-form-wrapper-error {
  display: "flex";
  flex-direction: column;
}

.selectForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 528px) {
    display: block;
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 252px;
  }

  .css-lr9fot-singleValue {
    color: $darken-gray !important;
    font-weight: 600;
    font-size: 14px;
    margin-left: 11px;
  }
  .css-1rqcln2-control {
    border-radius: 7px;
  }
  label {
    color: $dark-gray-1;
  }
  .css-qc6sy-singleValue {
    font-size: 14px;
    margin-left: 7px;
  }

  .css-1t8y0t9-control {
    width: 100%;
    height: 34px;
    border: 1px solid $lighter-gray-14;
    border-radius: 7px;

    outline: none;
    @media (max-width: 375px) {
      width: 86%;
    }
  }
}
.addUserModal {
  .MuiDialog-container > .MuiPaper-root {
    width: 1500px;
  }
}
