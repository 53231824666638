@use "../../assets/scss/utilities" as *;

.td {
  display: flex;
  gap: 13px;
  align-items: center;
  // white-space: nowrap;
  & > div {
    //---td with bg
    .job-status {
      width: 137px;
      height: 46px;
      border-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .part-time {
      background: $lighter-red-3;
      color: $lighter-red !important;
    }
    .full-time {
      background: $lighten-green-1;
      color: $light-green !important;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      $white-space: nowrap;
      font-family: "Poppins" !important;

      //---td with bg
      color: $black;
    }
   
    .user-name {
      font-weight: 600 !important;
      font-size: 15px !important;
    }
    .description {
      font-weight: 500;
      font-size: 12px;
      font-family: "Poppins" !important;
      line-height: 16px;
      color: $dark-gray-1;
      white-space: nowrap;
    }
  }
}
.user-img {
  & > img {
    width: 47px;
    height: 46px;
    border-radius: 50%;
  }
}
.leave-type-img {
  & > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.leave {
  font-weight: 500 !important;
}
.sick-leave {
  color: $lighter-red !important;
}
.casual-leave {
  color: $purple !important;
}
.personal-leave {
  color: $blue !important;
}
.vacation-leave {
  color: $orange !important;
}

.technical-approval,
.hr-approval {
  color: $lighter-yellow !important;
}
.rejected-status {
  color: $lighter-red !important;
}
.approved-status {
  color: $light-green !important;
}
.negotiation-status {
  color: $orange !important;
}

.status-icon {
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
.hr-approval-icon,
.technical-approval-icon {
  border: 2px solid $lighter-yellow;
  color: $lighter-yellow;
}
.rejected-status-icon {
  border: 2px solid $lighter-red;
  color: $lighter-red;
}
.approved-status-icon {
  border: 2px solid $light-green;
  color: $light-green;
}
.negotiation-status-icon {
  border: 2px solid $orange;
  color: $orange;
}

.table-actions {
  display: flex;
  justify-items: center;
  gap: 20px;
  div {
    width: 46.53px;
    height: 46px;
    border: 1px solid $lighter-gray-14;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: $lighter-red-6;
    }
  }

  .reject-action {
    & > .MuiSvgIcon-root {
      color: $light-red-1;
    }
  }
  .pending-action {
    & > .MuiSvgIcon-root {
      color: $lighter-yellow;
      font-size: 29px;
    }
  }
  .approve-action {
    & > .MuiSvgIcon-root {
      color: $light-green;
    }
  }
}

.collapse-btn {
  background: $lighter-gray-14;
  width: 34px;
  height: 68px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leavetypes-action {
  gap: 0 !important;
  .edit-action {
    border: none !important;
  }
  .delete-action {
    border: none !important;

    & > img {
      width: 20px;
    }
  }
}

.table-component {
  .table-container {
    .table-content {
      & > .MuiTableHead-root {
        tr {
          th {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $darker-gray-5;
            padding-bottom: 6px;
            z-index: auto;
          }
        }
      }
      & > .MuiTableBody-root {
        & tr {
        //  word-break: break-all;
          cursor: pointer;
          &:hover {
            background: $lighter-gray-12;
          }
          .leave-types-table {
            width: 30%;
          }
        }

        .selected-leave {
          background: $lighter-blue-2;
          &:hover {
            background: darken($lighter-blue-2, 2%);
          }
        }
      }
    }
  }
  & .MuiTableCell-stickyHeader {
    $white-space: nowrap;
  }
}
