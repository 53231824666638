@use "../../assets/scss/utilities" as *;

button {
  border-radius: 10px;
  outline: none;
  border: none;
  line-height: 24px;
  font-weight: 900;
  font-size: 17px;
  cursor: pointer;
}

.login-btn {
  border-radius: 14px !important;
  width: 459px;
  height: 64px;
  background: $light-blue;
  margin: 60px 0 16px 0;
  color: $white;
  @media (max-width: 506px) {
    width: 100%;
  }
  @media (max-width: 466px) {
    width: 100%;
  }
}

.Login-google-btn {
  all: unset;
  border-radius: 14px !important;
  width: 459px;
  height: 64px;
  background: $white !important;
  margin: 13px 0 118px 0 !important;
  border: 2px solid $lighter-gray-14 !important;
  color: $black !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  flex-wrap: wrap;

  & > div {
    margin: 0 !important;
    padding: 0 !important;

    & > svg {
      width: 18px !important;
      height: 18px !important;
    }
  }

  & > span {
    font-size: 17px !important;
    font-weight: 600 !important;
    font-family: Poppins;
  }
  @media (max-width: 530px) {
    width: 100%;
  }
}

.disabled-button {
  background-color: $lighter-gray-13;
  color: $darker-gray-5;
  cursor: default;
}
.blue-disabled-button {
  background-color: $lighter-gray-13;
  color: $darker-gray-5;
  cursor: default;
  width: 128px;
}

.costum-btn {
  all: unset;
  box-sizing: border-box;
  border: 1.5px solid $lighter-gray-14;
  border-radius: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
}

.blue-btn {
  background: $light-blue;
  color: $white;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  &:hover {
    background: darken($light-blue, 7%);
    border-color: darken($lighter-gray-14, 7%);
  }
}

.white-btn {
  background: $white;
  color: $black;
  cursor: pointer;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  &:hover {
    background: darken($white, 7%);
    border-color: darken($lighter-gray-14, 7%);
  }
}

.small-btn {
  margin-top: 2px;
  width: 77px;
  height: 43px;
}
.medium-btn {
  width: 120px;
  height: 46px;
}

.cancel-btn {
  background: $lighter-gray-2;
  &:hover,
  &:focus {
    background: $dark-gray-2;
  }
}
.delete-btn,
.reject-btn {
  background: $lighter-red-1;
  &:hover,
  &:focus {
    background: $light-red-2;
  }
}
.BlueButton {
  background-color: $sky-blue;
  color: $white;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
}
