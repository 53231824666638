.progressbar_container {
  margin-bottom: 178;
  text-align: center;
  width: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  @media (max-width: 530px) {
    width: 29%;
  }
}
.width {
  width: 25%;
}
