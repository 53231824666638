@use "../../assets/scss/utilities" as *;

//standard inputs

.form-control {
  label {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  }

  input + label {
    font-weight: bold;
    display: inline-flex;
  }

  input,
  textarea {
    width: 301px;
    height: 49px;
    border: none;
    outline: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: $darken-gray;
    background-color: $white;
    background-image: none;
    border: 2px solid $lighter-gray-14;
    border-radius: 14px;
    display: flex;
    align-items: center;
    text-align: start;
    padding-left: 18px;
    padding-right: 18px;
    &:focus {
      border: 2px solid $sky-blue !important;
    }
    &::placeholder {
      font-size: 15px;
      color: $darker-gray;  
      font-weight: 400;
    }

    &:hover {
      border: 2px solid $sky-blue !important;
    }
  }
}

//input with icons

.field-global-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid $lighter-gray-14;
  border-radius: 14px;
 // width: 460px;
  height: 63px;
  display: flex;
  align-items: center;
  text-align: start;
  padding-left: 18px;
  padding-right: 28px;

  .field-intern-wrapper {
    display: flex;
    align-items: center;
    text-align: start;
    gap: 7px;
    width: 100%;
    height: 92%;
    .DatePicker {
      width: 95%;
      
    }
    input {
      border: none;
      outline: none;
      width: 100%;
      height: 85%;
      text-align: start;
      &::placeholder {
        font-size: 15px;
      color: $darker-gray;  
      font-weight: 400;
      }
    }
    img {
      padding-bottom: 4px;
    }
  }
  &:focus-within {
    border: 2px solid  $sky-blue !important;
  }
  &:hover {
    border: 2px solid $sky-blue !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

//both

textarea {
  resize: none;
}

//error

.error {
  margin: 0;
  color: $light-red;
  font-size: 11px;
  display: flex;
  padding-top: 4px;
  svg {
    width: 17px;
    height: 14px;
  }
}

.error-field {
  max-width: 465px;
  .field-global-wrapper {
    color: $light-red;
    border: 2px solid $light-red !important;
  }
}
.error-input {
  & input {
    border: 2px solid $light-red !important;
    &:focus {
      border: 2px solid $light-red !important;
    }
    border: 2px solid $light-red !important;
  }
}

//disabled

.disabled {
  pointer-events: none;
  & input:hover,
  textarea:hover {
    border: 2px solid $lighter-gray-14 !important;
  }
}
