.success-alert {
  color: rgb(30, 70, 32) !important;
  background-color: rgb(237, 247, 237) !important;
}
.error-alert {
  color: rgb(95, 33, 32) !important;
  background-color: rgb(253, 237, 237) !important;
}
.info-alert {
  color: rgb(1, 67, 97) !important;
  background-color: rgb(229, 246, 253) !important;
}
