@use "../../assets/scss/utilities" as *;

//TimePicker style
.TimePicker_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 631px) {
    display: block;
  }

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    @media (max-width: 631px) {
      width: 100%;
    }
  }

  span {
    font-size: 25px;
    color: #555555;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    color: black !important;
    margin-left: 15px;

    @media (max-width: 631px) {
      width: 100%;
    }
  }

  //style on production mode

  .css-1bn53lx {
    border-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    color: black !important;
    border: 1px solid #ebebeb;
    //margin-left: 25px;
    position: initial !important;
    width: 100%;
  }

  .css-igs3ac {
    border: none !important;
  }

  .css-i44wyl {
    vertical-align: baseline;
    height: 46px;
    flex-direction: row;
    display: inline-flex;
    padding: 2px !important;
    margin-left: 25px !important;

    @media (max-width: 631px) {
      width: 100%;
      margin-left: 0px !important;
    }
  }

  .css-cysikj {
    background-color: #2ba7df !important;
  }

  .css-7lip4c {
    background-color: #2ba7df !important;
  }

  .css-12ha4i7 {
    background-color: #2ba7df !important;
  }

  .css-118whkv {
    background-color: #2ba7df !important;
    border: 16px solid #2ba7df !important;
  }

  .css-2ujp1m {
    border: 16px solid #2ba7df;
  }

  .css-1bn53lx:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #2ba7df !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid #2ba7df !important;
  }

  .css-1uvydh2 {
    font-size: 20px;
    font-weight: 400;
  }

  ///
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid #2ba7df !important;
  }

  .css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
    background-color: #2ba7df !important;
  }

  .css-d0vs79-MuiClockPointer-root {
    background-color: #2ba7df !important;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 20px;
    font-weight: 400;
    padding: 9.5px 14px;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #ebebeb;
    height: 50px;
  }

  .MuiSvgIcon-root {
    color: #000000;
  }

  margin-bottom: 39px;

  .endtime_wrapper {
    margin-left: 15px;

    @media (max-width: 631px) {
      margin-left: 0px;
    }
  }
}

.datepicker_wrapper {
  margin-bottom: 39px;
}

.time_label {
  margin-bottom: 10px;
  font-weight: 500, Medium;
  color: #636363;
}

/// progressbar style
.progress_bar_values {
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 500;
    font-size: 18;
    color: #636363;
  }
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 16px;
}

.progress-done {
  background: linear-gradient(264.05deg, #8ad5f7 18.08%, #2ba7df 99.17%);
  box-shadow: 0 3px 3px -5px #706e6e, 0 2px 5px #706e6e;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  opacity: 0;
  transition: 1s ease 0.3s;

  span {
    font-size: 13;
  }
}

.error {
  margin: 0;
  color: $light-red;
  font-size: 11px;
  display: flex;
  padding-top: 4px;

  svg {
    width: 17px;
    height: 14px;
  }

  .MuiSvgIcon-root {
    color: $light-red;
  }
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: rgb(0, 162, 255) !important;
}
