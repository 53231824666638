.updata-user-modal-main {
  margin-left: 30px;
 // width: 1500px;
  margin-bottom: 8%;
  margin-right: 30px;
 
}


.header-upload-user-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  align-items: baseline;

  @media (max-width: 768px) {
    display: block;
  }
}


.updateUserModal{
  .MuiDialog-container > .MuiPaper-root {
    width: 1500px;
  }
}