@use "../../assets/scss/utilities" as *;

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lighter-gray-14;
  .Header__title {
    height: 90px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 23px;
    span {
      margin-left: 44px;
      margin-right: 15px;
    }
  }
  .Header__notif {
    display: flex;
    align-items: center;
    gap: 18px;

    .notif__img {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 35px;
        width: 35px;
      }
    }
  }
}
