@use "../../../assets/scss/utilities" as *;

.EmployeeDetails__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 746px) {
    display: block;
    margin-bottom: 20px;
  }


  .employeeDetails__identity {
    display: flex;
    align-items: center;
    margin-bottom: 19px;
    margin-right: 50px;

    img {
      width: 70px;
      height: 70px;
    }
    .employeeDetails__identity_name {
      display: flex;
      flex-direction: column;
      span {
        font-size: 20px;
        font-weight: 600;
        margin-left: 20px;
      }

      p {
        margin: 0;
        font-size: 15px;
        font-weight: 400, Regular;
        margin-left: 20px;
      }
    }
  }

  .button_wraper {
    display: flex;
    .delete_button {
      color: $lighter-red;
      width: 110px;
      height: 43px;
      border: 1.5px solid $lighter-red;
      margin-right: 12px;
      font-weight: 600;
      font-size: 16px;
      background: $white;
    }
    .edit-button {
      font-weight: 600;
      font-size: 16px;
      color: $black;
      width: 152px;
      height: 43px;
      border: 1.5px solid $lighter-gray-14;
      background: $white;
    }
  }
}
.subtitle__employee_info {
  font-size: 16px;
  margin-bottom: 19px;
  margin-top: 0;
}

.info_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
