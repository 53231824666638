@use "../../assets/scss/utilities" as *;

.wrraper-photo-upload {
  display: flex;
  align-items: center;
  height: 94px;
  .photo-upload {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    margin-right: 49px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }

  .button-upload {
    color: $darker-gray-5;
    background: $lighter-gray-13;
    font-size: 16px;
    font-weight: 600;
  }
}
input[type="file"] {
  color: transparent;
  width: 41%;
}
.photo-upload-form {
  display: flex;
  align-items: center;
  ::-webkit-file-upload-button {
    width: 152px;
    height: 47px;
    border: 1px solid $lighter-gray-14;
    background-color: $white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
  }
}
