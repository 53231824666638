@use "../../assets/scss/utilities" as *;

.min-calendar-wrraper {
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 35px;

  .react-calendar__month-view__days__day--weekend {
    color: $black;
    font-size: 19px;
  }

  .react-calendar__navigation button:disabled {
    background-color: $white;
  }

  .min-calendar-wrraper .react-calendar__navigation button:disabled {
    background-color: $white;
  }

  .react-calendar__tile--now {
    background: $white;
  }

  .react-calendar {
    border: none;
    width: 549px;

    .react-calendar__tile--active {
      background: $dark-yellow;
      color: $black;
    }
    .react-calendar__tile--rangeStart {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      background: $dark-yellow;
    }

    .react-calendar__tile--rangeEnd {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      background: $dark-yellow;
    }

    button {
      height: 55px;
      border-radius: 0px;
      font-weight: 700;
    }

    .react-calendar__month-view__days {
      height: 383px;
    }
  }

  .react-calendar * {
    font-size: 19px;
    font-weight: 600, Semi Bold;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: $dark-gray-3;
    font-weight: 400;
    font-family: "Poppins";
    font-style: normal;
  }

  abbr[title] {
    text-decoration: none;
  }
}

.min-calendar-wrraper .react-calendar .react-calendar__month-view__days {
  height: 300px;
}

.LeaveItem--wrapper {
  border-top: 2px solid $lighter-gray-14;
  @media (max-width: 1193px) {
    width: 100%;
  }
}

.margin-right {
  margin-right: 38px;
  .leave-request-progressbar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: 72px;

    @media (max-width: 1193px) {
      width: 100%;
    }
  }
}
.react-calendar__navigation__label {
  background: $white !important;
}
.react-calendar__tile .react-calendar__month-view__days__day {
  background: $white !important;
}

.react-calendar__tile
  .react-calendar__month-view__days__day
  .react-calendar__month-view__days__day--neighboringMonth {
  background-color: $white;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: $white;
}

.calender-big-wrapper-1 {
  display: flex;
  flex-direction: column;
  //margin-left: 30px;
  @media (max-width: 1193px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
