@use "../../assets/scss/utilities"as *;

.NotifItem__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 375px) {
    display: inline;
  }

  .wrraper-photo-upload {
    height: 0px;
  }

  .css-1q2h7u5.Mui-selected {
    color: #0aafdf;

  }

  .css-ttwr4n {
    background-color: #0aafdf;
    ;
  }
}

.NotifItem__msg {
  display: flex;
  flex-direction: column;
}

.NotifItem__msg_title {
  color: $black;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
}

.NotifItem__msg_title__seen {
  color: $black;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
}

.NotifItem__img {
  margin-right: 11px;
  display: flex;
  align-items: center;
  align-items: center;

  @media (max-width: 375px) {
    display: none;
  }
}

.main-item-wrapper-seen {
  background: $lighter-blue-1;
}

.mainItemwapper {
  .MoreHorizIcon-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;

    svg {
      height: 25px;
      width: 25px;
      color: $lighter-red-2;
    }

    @media (max-width: 425px) {
      svg {
        display: none;
      }
    }

    :hover {
      height: 30px;
      width: 30px;
      border-radius: 50px;
    }
  }

  .css-1km1ehz {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.delete-style {
  display: flex;
  align-items: center;

  span {
    font-size: 17px;
    font-weight: 600;
  }

  svg {
    margin-right: 5px;
  }
}

.Notif_container__big__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 8px;
  font-weight: 600;
  margin-bottom: 7px;

  img {
    width: 34px;
    height: 34px;
  }


}

.css-1q2h7u5.Mui-selected {
  color: #0aafdf !important;
}
.css-ttwr4n {
  background-color: #0aafdf !important;
}

.MuiBox-root .css-1gsv261 {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $white;
}

.MuiBox-root .css-19kzrtu {
  padding: 0px;
}

.MoreHorizIcon-icon-notification {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  background-color: $lighter-gray-10;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &>svg {
    height: 17px;
    width: 17px;
  }

  :hover {
    transform: rotate(90deg) !important;
  }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-weight: 600;
  color: $light-blue !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: $light-blue !important;
}

.dropdownMenuMain {
  .MuiPaper-root {
    border-radius: "16px !important";
  }

  .jss2 {
    border-radius: "16px !important";
  }
}