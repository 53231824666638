@use "../../assets/scss/utilities" as *;

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.modal_header {
  background: $lighter-gray-14;
}
.title-modals {
  font-size: 19px;
  font-weight: 600;
}

.close_button {
  width: 36px;
  height: 36px;
  background: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blueButton {
  width: 128px;
  height: 43px;
  cursor: pointer;
  @include blueButton();
  @include buttonText();
}

.whiteButton {
  background: $white;
  width: 128px;
  height: 43px;
  border: 1px solid $lighter-gray-10;
  @include buttonText();
}

.ta-btns-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-bottom: 22px;
  *:not(:first-child) {
    margin-right: 12px;
  }
}

.ta-modal {
  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      border-radius: 20px;
      min-width: 792px;
      @include mq(775px, max) {
        min-width: 80%;
      }
      & > .MuiDialogTitle-root {
        & > .MuiTypography-root {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: auto;
          & > .label {
            font-size: 20px;
            color: $sky-blue;
            @include mq(775px, max) {
              font-size: 16px;
            }
          }
          & > .close-btn {
            font-size: 12px;
            color: $sky-blue;
            font-weight: 600;
            cursor: pointer;
            transition: all 300ms ease;
            &:hover {
              color: $darker-blue-1;
            }
          }
        }
      }
    }
  }
}
