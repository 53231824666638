.main-layout {
  display: flex;

  & > .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1626px) {
      width: 95%;
    }

    // @media (max-width: 896px) {
    //   width:70%;
    // }

    // @media (max-width: 790pxNotifItem__container) {
    //   width:66%;
    // }

    @media (max-width: 768px) {
      width: 92%;
    }
    // @media (max-width: 720px) {
    //   width: 91%;
    // }
    @media (max-width: 425px) {
      width: 85%;
    }
  }
  .children_container {
    margin-left: 41px;
    margin-right: 20px;

    // @media (max-width: 728px) {
    //   width: 88%;
    // }

    @media (max-width: 1032px) {
      width: 90%;
    }
    @media (max-width: 542px) {
      margin-left: 14px;
      //  width: 100%;
    }
    @media (max-width: 375px) {
      margin-right: 0px;
    }
  }
}
.density-smallIcon-style {
  display: flex;
  justify-content: center;
  margin-bottom: 50%;
  margin-top: 50%;
  cursor: pointer;
}
