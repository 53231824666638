@use "../../assets/scss/utilities" as *;

.item-wrraper {
  width: 90%;
 // height: 60px;
  border-radius: 5px;
  border: 2px solid $lighter-gray-14;
  border-left: 6px solid $light-orange;
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 11px;
  margin-bottom: 20px;
  span {
    color: $darker-gray-5;
    font-weight: 400, Regular;
    font-size: 16px;
  }
  p {
    font-weight: 600;
    font-size: 21px;
    margin-top: 0px;
  }
}
