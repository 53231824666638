@use "../../../assets/scss/utilities" as *;

.collapse {
  .MuiGrid-grid-md-6 {
    max-width: 100% !important;
  }
  & > .MuiGrid-container {
    .collapse-content {
      padding-top: 38px;
      display: flex;
      flex-direction: row;
      gap: 46px;

   //   flex-wrap: wrap;

      overflow-x: auto;
      .user-img {
        & > img,
        & > .MuiAvatar-root {
          width: 69px;
          height: 68px;
          & > svg {
            width: 100%;
            height: 75%;
          }
        }
      }
      .user-data {
        display: flex;
        gap: 14px;
        padding-bottom: 27px;


        
        //
       // flex-wrap: wrap;
        .user-information,
        .job-information,
        .leave-information,
        .user-contact-information {
          padding-right: 17px;
          gap: 15px;
          display: flex;
          flex-direction: column;
        }

        .user-information,
        .job-information,
        .leave-information {
          border-right: 2px solid $lighter-gray-14;
        }

        .td {
          width: 137px;
          & > .td-content {
            flex-direction: row !important;
            gap: 4px;
          }

          & > div {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            gap: 90px;
            $white-space: nowrap;
          }

          .text {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: $black;
            word-break: break-all;
          }
          .description {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: $dark-gray-1;
          }

          .job-status {
            width: 86px;
            height: 39px;
          }
        }
      }
    }
    .collapse-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // padding-left: 15%;
      padding-top: 0px;

      & > svg {
        font-size: 24px;
        cursor: pointer;
      }
      & > img {
        cursor: pointer;
      }
    }
  }
}

.user-information-content,
.job-information-content,
.leave-information-content,
.user-contact-information-content {
  display: flex;
  margin-right: 12px;
}
