// File: App.scss
// This file contains all styles related to the site/application.
@use "./assets/scss/utilities" as *;
@use "./layouts/MainLayout";
* {
  ::-webkit-scrollbar {
    display: auto;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    background-color: transparentize($lighter-gray-4, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparentize($lighter-gray-4, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: $app-background;
  margin: 0;
}

#root {
  height: 100vh;
  overflow-x: clip;
}
